@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

*::-ms-expand {
  display: none;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
 v2.0 | 20110126
 License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
margin: 0;
padding: 0;
border: 0;
font-size: 100%;
font: inherit;
vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

* { box-sizing:border-box; }

html, body {

	letter-spacing:0px !important;
	font-size : 14px;
	line-height : 30px;
	margin : 0 auto;
	color:gray;
	padding : 0 0 0 0;
	height: 100%;
	width:100%;
	min-width:100%;
	letter-spacing:0px;
	background:#fff;

}
* { font-family: 'Pretendard' !important; }
select {

  -webkit-appearance:none; 

  -moz-appearance:none;

  appearance:none;
-webkit-border-radius: 0;
border-radius:0;
}

* {  -moz-transition: all .2s ease-out;-ms-transition: all .2s ease-out;-o-transition: all .2s ease-out; transition: all .2s ease-out; }

select::-ms-expand{

  display:none;

}
* { outline:none; }
*{
  scrollbar-width: thin;
  scrollbar-color: #00309c;
}
*::-webkit-scrollbar {
  width: 12px;
  height:12px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: #c8cace;
  border-radius: 6px;
  background-clip: padding-box;
  border: 3px solid transparent;
}
*::-webkit-scrollbar-thumb:hover {
  background: #888;
  background-clip: padding-box;
}
* { letter-spacing:0px; }
a { text-decoration:none; }
.section_inner hr { opacity:0.2; }

.wrap { height:auto;position:relative;margin:0px;padding:0px;width:100%;min-width:1500px;padding-top:100px; }
header { min-width:1500px;width:100%;height:100px;border-bottom:1px solid #e5e5e5;position:fixed;top:0px;left:0px;background:white;z-index:9999;padding-left:66px;padding-right:411px;overflow:hidden; }
header a.logo { width:40px;height:40px;background:url('./img/logo1.png') no-repeat;background-size:100% 100%;display:block;float:left;margin-top:30px; }
header a.download { width:210px;height:100px;position:absolute;top:0px;right:0px;display:block;border-left:1px solid #e2e2e2;text-align:center;line-height:100px;font-size:16px;font-weight:normal;color:black; }
header a.download i { display:inline-block;width:26px;height:26px;background:url('./img/download_icon.png') no-repeat;background-size:100% 100%;vertical-align:middle;margin-right:10px; }
header a.download:hover { background:#f4f4f4;text-decoration: none; }
header a.download:hover div { background:red;color:white; }
header a.download:hover div i { display:inline-block;width:26px;height:26px;background:url('./img/download_icon_w.png') no-repeat;background-size:100% 100%;vertical-align:middle;margin-right:10px; }
/* header a.pc_move { width:201px;height:100px;position:absolute;top:0px;right:210px;display:block;border-left:1px solid #e2e2e2;text-align:center;line-height:100px;font-size:16px;font-weight:normal;color:black; }
header a.pc_move i { display:inline-block;width:26px;height:26px;background:url('./img/pc_icon.png') no-repeat;background-size:100% 100%;vertical-align:middle;margin-right:10px; }
header a.pc_move:hover { background:#f4f4f4;text-decoration: none; }
header a.pc_move:hover div { background:red;color:white }
header a.pc_move:hover div i { display:inline-block;width:26px;height:26px;background:url('./img/pc_icon_w.png') no-repeat;background-size:100% 100%;vertical-align:middle;margin-right:10px; } */
header a.pc_move { width:201px;height:100px;position:absolute;top:0px;right:210px;display:block;text-align:center;line-height:100px;font-size:16px;background:#f4f4f4;text-decoration: none; }
header a.pc_move div { background:red;color:white }
header a.pc_move div i { display:inline-block;width:26px;height:26px;background:url('./img/pc_icon_w.png') no-repeat;background-size:100% 100%;vertical-align:middle;margin-right:10px; }
header ul { float:right;display:inline-block;height:100px;width:auto;margin:0px;padding:0px;padding-right:60px; }
header ul li { display:inline-block;vertical-align:top;height:100px;line-height:100px;padding-left:44px;cursor:pointer; }
header ul li:hover a { color:black;font-weight:bold;text-decoration:underline; }
header ul li a { font-size:16px;font-weight:normal;color:#585858; }
header ul li a.active { color:#000 !important;font-weight:bold !important;text-decoration:underline !important; }


header.scrolled { min-width:1500px;width:100%;height:60px;border-bottom:1px solid #e5e5e5;position:fixed;top:0px;left:0px;background:white;z-index:9999;padding-left:66px;padding-right:411px;overflow:hidden; }
header.scrolled a.logo { width:40px;height:40px;background:url('./img/logo1.png') no-repeat;background-size:100% 100%;display:block;float:left;margin-top:10px; }
header.scrolled a.download { width:210px;height:60px;position:absolute;top:0px;right:0px;display:block;border-left:1px solid #e2e2e2;text-align:center;line-height:60px;font-size:16px;font-weight:normal;color:black; }
header.scrolled a.download i { display:inline-block;width:26px;height:26px;background:url('./img/download_icon.png') no-repeat;background-size:100% 100%;vertical-align:middle;margin-right:10px; }
header.scrolled a.download:hover { background:#f9f9f9;text-decoration: none; }

header.scrolled a.pc_move { width:201px;height:60px;position:absolute;top:0px;right:210px;display:block;border-left:1px solid #e2e2e2;text-align:center;line-height:60px;font-size:16px;font-weight:normal;color:black; }
header.scrolled a.pc_move i { display:inline-block;width:26px;height:26px;background:url('./img/pc_icon.png') no-repeat;background-size:100% 100%;vertical-align:middle;margin-right:10px; }
header.scrolled a.pc_move:hover { background:#f9f9f9;text-decoration: none; }

header.scrolled ul { float:right;display:inline-block;height:60px;width:auto;margin:0px;padding:0px;padding-right:60px; }
header.scrolled ul li { display:inline-block;vertical-align:top;height:60px;line-height:60px;padding-left:44px;cursor:pointer; }
header.scrolled ul li:hover a { color:black;font-weight:bold;text-decoration:underline; }
header.scrolled ul li a { font-size:16px;font-weight:normal;color:#585858; }
header.scrolled ul li a.active { color:#000 !important;font-weight:bold !important;text-decoration:underline !important; }



section { width:100%;height:auto;position:relative; }
section .section_inner { width:1450px;height:auto;margin:0px auto;padding-top:160px;padding-bottom:160px; }
section .section_inner:after { clear:both;content:'';display:block; }
section .section_inner h2 { font-size:50px;font-weight:bold;color:black;display:block;margin:0px;padding:0px;text-align:center;width:100%;height:auto;margin-bottom:60px; }

.pc_big { width:956px;height:600px;float:left;background:url('./img/pc.png') no-repeat;background-position:center center; }
.pc_big_rest { width:calc( 100% - 956px );height:600px;float:left;text-align:left;padding-top:40px;padding-left:40px;line-height:27px;font-size:17px;font-weight:normal;color:black; }

.pc_big_rest h1 { display:block;height:auto;width:100%;font-size:52px;font-weight:normal;color:black;margin:0px;padding:0px;margin-bottom:70px;line-height:70px; } 
.pc_big_rest h1 b { font-weight:bolder; }
.pc_big_rest table { width:50%;height:auto;border-collapse:collapse;border:none;margin-bottom:50px; }
.pc_big_rest table td { text-align:center;font-size:14px;font-weight:normal;color:black; }
.pc_big_rest table td a { font-size:inherit;font-weight:inherit;color:inherit;font-style:inherit;} 
.pc_big_rest table td div { width:64px;height:64px;border:1px solid #d4d4d4;border-radius:50%;margin:0px auto; }
.pc_big_rest table td a:hover div { border:1px solid black; } 
.pc_big_rest table td:nth-child(1) div { background:url('./img/play_store.png') no-repeat;background-position:center center;margin-bottom:10px; }
.pc_big_rest table td:nth-child(2) div { background:url('./img/app_store.png') no-repeat;background-position:center center;margin-bottom:10px; }

.bg_light_gray { background:#efefef }  

.dopyo { width:879px;height:708px;background:url('./img/dopyo.png') no-repeat;background-size:100% 100%;margin:0px auto;margin-top:120px;margin-bottom:70px; }
span.text_align_center { font-weight:bold;color:black;font-size:29px;line-height:39px;text-align:center;display:block; }

.history { float:left;width:545px;height:auto;padding-left:95px;text-align:left;margin-top:60px; }
.history_rest { float:left;width:calc( 100% - 545px );height:auto;padding-left:70px;margin-top:120px;padding-top:20px; }
.hostory_cell { width:100%;height:auto;border-left:1px solid #d4d4d4;font-size:19px;font-weight:normal;color:#535353;text-align:left;padding-left:30px;position:relative; }
.hostory_cell b { font-size:60px;font-weight:lighter;color:black;display:block;line-height:90px;width:100%;margin-bottom:20px;position:relative;margin-left:-30px;padding-left:30px; }
.hostory_cell b:before { content:'';display:block;width:6px;height:6px;border-radius:50%;background:red;position:absolute;left:-4px;top:50%;margin-top:-3px; }
.hostory_cell span { color:red; } 
.hostory_cell:before { content:'';background:white;width:4px;height:42px;display:block;top:0px;left:-2px;position:absolute; }
.hostory_cell:after { content:'';background:white;width:4px;height:122px;display:block;bottom:0px;left:-2px;position:absolute; }

.history_rest b { font-size:50px;font-weight:normal;color:black;display:block;margin-bottom:70px; }

.branch { width:160px;height:160px;background:White;border:1px solid #d9d9d9;display:inline-block;vertical-align:top;margin-right:29px;margin-bottom:29px;border-radius:50%; } 
.branch:nth-of-type(1) { background:white url('./img/br_05.png') no-repeat;background-position:center center; }
.branch:nth-of-type(2) { background:white url('./img/br_12.png') no-repeat;background-position:center center; }
.branch:nth-of-type(3) { background:white url('./img/br_06.png') no-repeat;background-position:center center; }
.branch:nth-of-type(4) { background:white url('./img/br_09.png') no-repeat;background-position:center center; }
.branch:nth-of-type(5) { background:white url('./img/br_07.png') no-repeat;background-position:center center; }
.branch:nth-of-type(6) { background:white url('./img/br_08.png') no-repeat;background-position:center center; }
.branch:nth-of-type(7) { background:white url('./img/br_10.png') no-repeat;background-position:center center; }
.branch:nth-of-type(8) { background:white url('./img/br_13.png') no-repeat;background-position:center center; }
.branch:nth-of-type(9) { background:white url('./img/br_04.png') no-repeat;background-position:center center; }
.branch:nth-of-type(10) { background:white url('./img/br_02.png') no-repeat;background-position:center center; }
.branch:nth-of-type(11) { background:white url('./img/br_11.png') no-repeat;background-position:center center; }

.bg_dark_gray { background:#3d3d3d; }

section.bg_dark_gray .section_inner h2 { font-size:50px;font-weight:bold;color:white;display:block;margin:0px;padding:0px;text-align:center;width:100%;height:auto;margin-bottom:60px; }

.app_intro_left { width:44%;height:auto;float:left;text-align:right;font-weight:lighter;color:white;font-size:32px;line-height:44px;margin-top:125px; }
.clear { clear:both; }
.app_intro_right { width:56%;height:790px;background:url('./img/app.png') no-repeat;background-position:center center;float:left; }
.app_intro_left h3 { display:block;font-size:66px;font-weight:normal;color:white;line-height:79px;margin-bottom:100px; }
.app_intro_left h3 b { font-weight:bolder; }
.app_intro_left table { width:34%;height:auto;border-collapse:collapse;border:none;margin-bottom:50px;float:right; }
.app_intro_left table td { text-align:center;font-size:14px;font-weight:normal;color:white; }
.app_intro_left table td a { font-size:inherit;font-weight:inherit;color:inherit;font-style:inherit;} 
.app_intro_left table td div { width:64px;height:64px;border:1px solid #444;border-radius:50%;margin:0px auto; }
.app_intro_left table td a:hover div { border:1px solid #000; } 
.app_intro_left table td:nth-child(1) div { background:url('./img/play_store.png') no-repeat;background-position:center center;margin-bottom:10px;filter:invert(100) }
.app_intro_left table td:nth-child(2) div { background:url('./img/app_store.png') no-repeat;background-position:center center;margin-bottom:10px;;filter:invert(100) }
.app_intro_left table td:nth-child(1) { padding-right:24px; }
b.big { display:block;width:100%;text-align:center;font-size:47px;font-weight:bold;color:white;margin-top:75px;margin-bottom:90px; } 

.process { width:100%;height:550px;background:url('./img/process.png') no-repeat;background-size:cover;background-position:center center; }

.warn { width:669px;height:737px;background:url('./img/warn.png') no-repeat;background-size:100% 100%;float:left; }
.warn_rest { width:calc( 100% - 669px );height:737px;float:left;padding-left:64px;color:white;text-align:left;font-size:24px;line-height:39px;font-weight:normal;padding-top:104px; }
.warn_rest b { font-size:32px;font-weight:bold;color:white; }
.warn_rest ul { padding-left:40px;margin-top:20px; }
.warn_rest ul li { font-size:30px;font-weight:normal;color:white;line-height:62px;list-style-type:disc; } 

.tab { width:100%;height:79px;border-bottom:1px solid #d4d4d4;margin-top:79px;border-left:0px solid #d4d4d4;border-right:1px solid #d4d4d4; }
.tab_cell { cursor:pointer;width:14.2%;float:left;border-top:1px solid #d4d4d4;border-left:1px solid #d4d4d4;margin-right:0px;text-align:center;line-height:79px;height:79px;background:#f4f4f4;color:black;font-size:17px;font-weight:normal;border-bottom:1px solid #d4d4d4; }
.tab_cell:last-of-type { width:14.8%; }
.tab_cell.ac_tab { border-top:4px solid red;border-bottom:1px solid white;background:white;line-height:75px;font-weight:bolder; }
.tab_cell:hover { text-decoration:underline; }
.tab_cell.ac_tab:hover { text-decoration:none; }
.tab_content { height:640px;width:100%;padding-top:100px;position:relative; }
.tab_content:after { clear:both;content:'';display:block; }
.phone_three { float:left;width:789px;height:506px;background:url('./img/phone_three.png') no-repeat;background-size:100% 100%; }
.phone_three7 { float:left;width:789px;height:506px;background:url('./img/phone_three7.png') no-repeat;background-size:100% 100%; }
.phone_three2 { float:left;width:789px;height:506px;background:url('./img/phone_three2.png') no-repeat;background-size:100% 100%; }
.phone_three3 { float:left;width:789px;height:506px;background:url('./img/phone_three3.png') no-repeat;background-size:100% 100%; }
.phone_three4 { float:left;width:789px;height:506px;background:url('./img/phone_three4.png') no-repeat;background-size:100% 100%; }
.phone_three5 { float:left;width:789px;height:506px;background:url('./img/phone_three5.png') no-repeat;background-size:100% 100%; }
.phone_three6 { float:left;width:789px;height:506px;background:url('./img/phone_three6.png') no-repeat;background-size:100% 100%; }
.phone_three_rest { float:left;width:calc( 100% - 789px );height:506px;text-align:left;padding-top:50px;font-size:24px;font-weight:lighter;color:black;line-height:39px; }
.phone_three_rest b { font-size:63px;font-weight:bold;color:black; }
.phone_three_rest b span { color:red; }
.phone_three_rest b.smaller { font-size:49px;font-weight:bold;color:black;margin-top:72px;display:block; }


.form { width:856px;margin:0px auto;height:auto;margin-top:86px;text-align:left;line-height:30px;font-size:16px;font-weight:bold;color:black; }
.form input[type="text"] { width:100%;height:55px;border:1px solid #d4d4d4;background:White;padding-left:20px;font-size:15px;font-weight:normal;color:black;margin-bottom:15px; }
.form textarea { width:100%;padding:20px;height:228px;border:1px solid #d4d4d4;resize:none;outline:none;font-size:15px;font-weight:normal;color:black;line-height:22px;margin-bottom:15px; }
.form pre { width:100%;padding:20px;height:228px;border:1px solid #d4d4d4;resize:none;outline:none;font-size:15px;font-weight:normal;color:black;line-height:22px;margin-bottom:15px;white-space:normal;word-break:keep-all;background:white;overflow:auto; }



.form .checks {position: relative;display:block;vertical-align:middle;;margin-right:0px;height:30px;margin:0px auto;margin-top:0px;line-height:30px;text-align:center;margin-top:-4px;  }
.form .checks input[type="checkbox"] { position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip:rect(0,0,0,0); border: 0; }
.form .checks input[type="checkbox"] + label { display: inline; position: relative; padding-left: 25px; cursor: pointer; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none;color:#555;font-weight:normal;font-size:0.929rem;text-align:left;float:left; }
.form .checks input[type="checkbox"] + label:before { content: ''; position: absolute; left: 0; top: 6px; width: 14px; height: 14px; text-align: center; background: #fff; border: 1px solid #c8cace; border-radius: 0%; box-shadow: none; }
.form .checks input[type="checkbox"] + label:active:before, .checks input[type="checkbox"]:checked + label:active:before { box-shadow: none; }
.form .checks input[type="checkbox"]:checked + label:before { background: #31126d url('./img/ch.svg') no-repeat;background-size:100% 100%; border-color: #31126d; }
.form .checks input[type="checkbox"]:checked + label:after { content: ''; position: absolute; top: 8px; left: 3px; width: 10px; height: 10px; background:#31126d ; border-radius: 0%; box-shadow: none;display:none; }
.form label { font-size:14px;font-weight:normal;color:gray;cursor:pointer; }

.form button { cursor:pointer;width:334px;height:56px;background:red;color:white;border:none;text-align:center;display:block;margin:0px auto;margin-top:57px;font-size:19px;font-weight:normal; }
.form button:hover { background:#a40000 }
.form span.big_span { text-align:center;width:100%;height:auto;display:block;font-size:22px;font-weight:normal;color:black;margin-top:55px; }


footer { border-top:13px solid red;height:222px;width:100%;background:#4a4a4a; }
.footer_inner { margin:0px auto;width:1450px;height:209px; }
.footer_logo { height:209px;width:280px;background:url('./img/footer_logo.png') no-repeat;float:left;background-position:center center; }
.footer_text { height:209px;width:906px;float:left;padding-left:60px;display:flex;align-items:center;font-size:14px;font-weight:normal;line-height:21px;color:white; }
.footer_etc { width:calc( 100% - 906px - 280px );height:209px;float:left;text-align:right;display:flex;align-items:center;font-size:14px;font-weight:normal;line-height:21px;color:white; } 
.footer_etc .youtube { width:24px;border-radius:50%;height:24px;display:inline-block;vertical-align:top;margin:3px;background:url('./img/youtube.png') no-repeat;background-size:cover;background-position:center center;margin-bottom:10px; }
.footer_etc .blog { width:24px;border-radius:50%;height:24px;display:inline-block;vertical-align:top;margin:3px;background:url('./img/blog.png') no-repeat;background-size:cover;background-position:center center;margin-bottom:10px; }
.footer_etc .facebook { width:24px;border-radius:50%;height:24px;display:inline-block;vertical-align:top;margin:3px;background:url('./img/facebook.png') no-repeat;background-size:cover;background-position:center center;margin-bottom:10px; }

table.download_table { border-top:2px solid red;width:100%;height:auto;border-collapse:collapse; }
table.download_table td:nth-of-type(1) { width:30%;padding:20px;border-bottom:1px solid #d4d4d4;text-align:left;font-size:22px;font-weight:bold;color:black; }
table.download_table td:nth-of-type(2) { width:20%;padding:20px;border-bottom:1px solid #d4d4d4;text-align:left;font-size:22px;font-weight:bold;color:black;border-right:1px solid #d4d4d4; }
table.download_table td:nth-of-type(3) { width:30%;padding:20px;border-bottom:1px solid #d4d4d4;text-align:left;font-size:22px;font-weight:bold;color:black;padding-left:20px; }
table.download_table td:nth-of-type(4) { width:20%;padding:20px;border-bottom:1px solid #d4d4d4;text-align:left;font-size:22px;font-weight:bold;color:black; }
table.download_table td:nth-of-type(2) a { width:50%;height:40px;font-size:17px;font-weight:bold;color:black;background:white;display:block;line-height:40px;text-align:center;border:1px solid gray; }
table.download_table td:nth-of-type(2) a:hover { background:red;color:white;border:1px solid red; }
table.download_table td:nth-of-type(4) a { width:50%;height:40px;font-size:17px;font-weight:bold;color:black;background:white;display:block;line-height:40px;text-align:center;border:1px solid gray; }
table.download_table td:nth-of-type(4) a:hover { background:red;color:White;border:1px solid red; }

.not_mobile { display:block !important; }
.on_mobile { display:none !important; }


header .toggle { width:60px;height:60px;position:absolute;right:0px;top:0px;border-left:1px solid #d4d4d4;display:none;justify-content:center;align-items:center;cursor:pointer; }
header .toggle:hover { background:#f4f4f4; }
header .toggle div { width:30px;height:30px;position:relative;margin-top:3px; }
header .toggle div span { width:100%;height:4px;background:black;display:block;margin-bottom:6px; }

@media all and (max-width:1250px) {

	

.wrap { height:auto;position:relative;margin:0px;padding:0px;width:100%;min-width:100%;padding-top:60px; }

header { min-width:100%;width:100%;height:60px;border-bottom:1px solid #e5e5e5;position:fixed;top:0px;left:0px;background:white;z-index:9999;padding-left:20px;padding-right:411px;overflow:hidden; }
header a.logo { width:40px;height:40px;background:url('./img/logo1.png') no-repeat;background-size:100% 100%;display:block;float:left;margin-top:10px; }
header a.download { width:170px;height:60px;position:absolute;top:0px;right:60px;display:block;border-left:1px solid #e2e2e2;text-align:center;line-height:60px;font-size:16px;font-weight:normal;color:black; }
header a.download i { display:inline-block;width:26px;height:26px;background:url('./img/download_icon.png') no-repeat;background-size:100% 100%;vertical-align:middle;margin-right:10px; }
header a.download:hover { background:#f9f9f9;text-decoration: underline; }

header a.pc_move { width:170px;height:60px;position:absolute;top:0px;right:230px;display:block;border-left:1px solid #e2e2e2;text-align:center;line-height:60px;font-size:16px;font-weight:normal;color:black; }
header a.pc_move i { display:inline-block;width:26px;height:26px;background:url('./img/pc_icon.png') no-repeat;background-size:100% 100%;vertical-align:middle;margin-right:10px; }
header a.pc_move:hover { background:#f9f9f9;text-decoration: underline; }

header ul { float:right;display:inline-block;height:60px;width:auto;margin:0px;padding:0px;padding-right:60px; }
header ul li { display:inline-block;vertical-align:top;height:60px;line-height:60px;padding-left:44px;cursor:pointer; }
header ul li:hover a { color:black;font-weight:bold;text-decoration:underline; }
header ul li a { font-size:16px;font-weight:normal;color:#585858; }
header ul li a.active { color:#000 !important;font-weight:bold !important;text-decoration:underline !important; }

header .toggle { width:60px;height:60px;position:absolute;right:0px;top:0px;border-left:1px solid #d4d4d4;display:flex;justify-content:center;align-items:center;cursor:pointer; }
header .toggle:hover { background:#f4f4f4; }
header .toggle div { width:30px;height:30px;position:relative;margin-top:3px; }
header .toggle div span { width:100%;height:4px;background:black;display:block;margin-bottom:6px; }
header .toggle.toggle_on div span { width:100%;height:4px;background:red;display:block;margin-bottom:6px; }


header.scrolled { min-width:100%;width:100%;height:60px;border-bottom:1px solid #e5e5e5;position:fixed;top:0px;left:0px;background:white;z-index:9999;padding-left:20px;padding-right:411px;overflow:hidden; }
header.scrolled a.logo { width:40px;height:40px;background:url('./img/logo1.png') no-repeat;background-size:100% 100%;display:block;float:left;margin-top:10px; }
header.scrolled a.download { width:170px;height:60px;position:absolute;top:0px;right:0px;display:block;border-left:1px solid #e2e2e2;text-align:center;line-height:60px;font-size:16px;font-weight:normal;color:black; }
header.scrolled a.download i { display:inline-block;width:26px;height:26px;background:url('./img/download_icon.png') no-repeat;background-size:100% 100%;vertical-align:middle;margin-right:10px; }
header.scrolled a.download:hover { background:#f9f9f9;text-decoration: underline; }

header.scrolled a.pc_move { width:170px;height:60px;position:absolute;top:0px;right:230px;display:block;border-left:1px solid #e2e2e2;text-align:center;line-height:60px;font-size:16px;font-weight:normal;color:black; }
header.scrolled a.pc_move i { display:inline-block;width:26px;height:26px;background:url('./img/pc_icon.png') no-repeat;background-size:100% 100%;vertical-align:middle;margin-right:10px; }
header.scrolled a.pc_move:hover { background:#f9f9f9;text-decoration: underline; }

header.scrolled ul { float:right;display:inline-block;height:60px;width:auto;margin:0px;padding:0px;padding-right:60px; }
header.scrolled ul li { display:inline-block;vertical-align:top;height:60px;line-height:60px;padding-left:44px;cursor:pointer; }
header.scrolled ul li:hover a { color:black;font-weight:bold;text-decoration:underline; }
header.scrolled ul li a { font-size:16px;font-weight:normal;color:#585858; }
header.scrolled ul li a.active { color:#000 !important;font-weight:bold !important;text-decoration:underline !important; }



section { width:100%;height:auto;position:relative; }
section .section_inner { width:100%;height:auto;margin:0px auto;padding-top:100px;padding-bottom:100px;padding-left:20px;padding-right:20px; }
section .section_inner:after { clear:both;content:'';display:block; }
section .section_inner h2 { font-size:50px;font-weight:bold;color:black;display:block;margin:0px;padding:0px;text-align:center;width:100%;height:auto;margin-bottom:60px; }

.pc_big { width:100%;height:auto;padding-top:60%;float:left;background:url('./img/pc.png') no-repeat;background-position:center center;background-size: 70% auto; }
.pc_big_rest { width:calc( 100% - 0px );height:600px;float:left;text-align:left;padding-top:40px;padding-left:40px;line-height:27px;font-size:17px;font-weight:normal;color:black; }

.pc_big_rest h1 { display:block;height:auto;width:100%;font-size:52px;font-weight:normal;color:black;margin:0px;padding:0px;margin-bottom:70px;line-height:70px; } 
.pc_big_rest h1 b { font-weight:bolder; }
.pc_big_rest table { width:50%;height:auto;border-collapse:collapse;border:none;margin-bottom:50px; }
.pc_big_rest table td { text-align:center;font-size:14px;font-weight:normal;color:black; }
.pc_big_rest table td a { font-size:inherit;font-weight:inherit;color:inherit;font-style:inherit;} 
.pc_big_rest table td div { width:64px;height:64px;border:1px solid #d4d4d4;border-radius:50%;margin:0px auto; }
.pc_big_rest table td a:hover div { border:1px solid black; } 
.pc_big_rest table td:nth-child(1) div { background:url('./img/play_store.png') no-repeat;background-position:center center;margin-bottom:10px; }
.pc_big_rest table td:nth-child(2) div { background:url('./img/app_store.png') no-repeat;background-position:center center;margin-bottom:10px; }

.bg_light_gray { background:#efefef }  

.dopyo { width:100%;height:auto;padding-top:90%;background:url('./img/dopyo.png') no-repeat;background-size:100% auto;background-position:center center;margin:0px auto;margin-top:120px;margin-bottom:70px; }
span.text_align_center { font-weight:bold;color:black;font-size:20px;line-height:39px;text-align:center;display:block; }

.history { float:left;width:100%;height:auto;padding-left:35px;text-align:left;margin-top:60px; }
.history_rest { float:left;width:calc( 100% - 0px );height:auto;padding-left:0px;margin-top:120px;padding-top:20px;text-align:center; }
.hostory_cell { width:100%;height:auto;border-left:1px solid #d4d4d4;font-size:19px;font-weight:normal;color:#535353;text-align:left;padding-left:30px;position:relative; }
.hostory_cell b { font-size:60px;font-weight:lighter;color:black;display:block;line-height:90px;width:100%;margin-bottom:20px;position:relative;margin-left:-30px;padding-left:30px; }
.hostory_cell b:before { content:'';display:block;width:6px;height:6px;border-radius:50%;background:red;position:absolute;left:-4px;top:50%;margin-top:-3px; }
.hostory_cell span { color:red; } 
.hostory_cell:before { content:'';background:white;width:4px;height:42px;display:block;top:0px;left:-2px;position:absolute; }
.hostory_cell:after { content:'';background:white;width:4px;height:122px;display:block;bottom:0px;left:-2px;position:absolute; }

.history_rest b { font-size:40px;font-weight:normal;color:black;display:block;margin-bottom:70px;text-align:center; }

.branch { width:160px;height:160px;background:White;border:1px solid #d9d9d9;display:inline-block;vertical-align:top;margin-right:14px;margin-left:14px;margin-bottom:29px;border-radius:50%; } 
.branch:nth-of-type(1) { background:white url('./img/br_05.png') no-repeat;background-position:center center; }
.branch:nth-of-type(2) { background:white url('./img/br_12.png') no-repeat;background-position:center center; }
.branch:nth-of-type(3) { background:white url('./img/br_06.png') no-repeat;background-position:center center; }
.branch:nth-of-type(4) { background:white url('./img/br_09.png') no-repeat;background-position:center center; }
.branch:nth-of-type(5) { background:white url('./img/br_07.png') no-repeat;background-position:center center; }
.branch:nth-of-type(6) { background:white url('./img/br_08.png') no-repeat;background-position:center center; }
.branch:nth-of-type(7) { background:white url('./img/br_10.png') no-repeat;background-position:center center; }
.branch:nth-of-type(8) { background:white url('./img/br_13.png') no-repeat;background-position:center center; }
.branch:nth-of-type(9) { background:white url('./img/br_04.png') no-repeat;background-position:center center; }
.branch:nth-of-type(10) { background:white url('./img/br_02.png') no-repeat;background-position:center center; }
.branch:nth-of-type(11) { background:white url('./img/br_11.png') no-repeat;background-position:center center; }

.bg_dark_gray { background:#3d3d3d; }

section.bg_dark_gray .section_inner h2 { font-size:50px;font-weight:bold;color:white;display:block;margin:0px;padding:0px;text-align:center;width:100%;height:auto;margin-bottom:60px; }

.app_intro_left { width:100%;height:auto;float:left;text-align:center;font-weight:lighter;color:white;font-size:32px;line-height:44px;margin-top:25px; }
.clear { clear:both; }
.app_intro_right { width:100%;height:auto;padding-top:90%;background:url('./img/app.png') no-repeat;background-size:auto 100%;background-position:center center;float:left;margin-top:50px; }
.app_intro_left h3 { display:block;font-size:46px;font-weight:normal;color:white;line-height:69px;margin-bottom:40px; }
.app_intro_left h3 b { font-weight:bolder; }
.app_intro_left table { width:50%;height:auto;border-collapse:collapse;border:none;margin-bottom:50px;float:none;margin:0px auto;margin-bottom:40px }
.app_intro_left table td { text-align:center;font-size:14px;font-weight:normal;color:white; }
.app_intro_left table td a { font-size:inherit;font-weight:inherit;color:inherit;font-style:inherit;} 
.app_intro_left table td div { width:64px;height:64px;border:1px solid #444;border-radius:50%;margin:0px auto; }
.app_intro_left table td a:hover div { border:1px solid #000; } 
.app_intro_left table td:nth-child(1) div { background:url('./img/play_store.png') no-repeat;background-position:center center;margin-bottom:10px;filter:invert(100) }
.app_intro_left table td:nth-child(2) div { background:url('./img/app_store.png') no-repeat;background-position:center center;margin-bottom:10px;;filter:invert(100) }
.app_intro_left table td:nth-child(1) { padding-right:24px; }
b.big { display:block;width:100%;text-align:center;font-size:27px;font-weight:bold;color:white;margin-top:75px;margin-bottom:90px; } 
.ovf { width:100%;height:570px;overflow:auto;position:relative; }
.process { width:1450px;height:550px;background:url('./img/process.png') no-repeat;background-size:cover;background-position:center center; }
.ovf_after { position:relative;height:570px;width:26px;background:url('./img/gra.png') no-repeat;background-size:100% 100%;z-index:22;margin-bottom:-570px;float:right;opacity:0.5; }
.warn { width:100%;height:auto;padding-top:90%;background:url('./img/warn.png') no-repeat;background-size:auto 100%;background-position:center center;float:left; }
.warn_rest { width:calc( 100% - 0px );height:auto;float:left;padding-left:0px;color:white;text-align:left;font-size:16px;line-height:29px;font-weight:normal;padding-top:7px; }
.warn_rest b { font-size:22px;font-weight:bold;color:white;display:block;margin-bottom:10px;margin-top:20px; }
.warn_rest ul { padding-left:20px;margin-top:10px;margin-bottom:10px; }
.warn_rest ul li { font-size:20px;font-weight:normal;color:white;line-height:32px;list-style-type:disc; } 
.warn_rest br { display:none; }
.tab { width:100%;height:150px;border-bottom:0px solid #d4d4d4;margin-top:79px;border-left:0px solid #d4d4d4;border-right:1px solid #d4d4d4; }
.tab_cell { cursor:pointer;width:33.3%;float:left;border-top:1px solid #d4d4d4;border-left:1px solid #d4d4d4;margin-right:0px;text-align:center;line-height:50px;height:50px;background:#f4f4f4;color:black;font-size:17px;font-weight:normal;border-bottom:1px solid #d4d4d4;margin-bottom:-1px;border-bottom:1px solid #d4d4d4; }
.tab_cell:nth-child(3n) { width:33.4%; }
.tab_cell:last-of-type { width:100%; }
.tab_cell.ac_tab { border-top:4px solid red;border-bottom:1px solid #d4d4d4;background:white;line-height:50px;font-weight:bolder; }
.tab_cell:hover { text-decoration:underline; }
.tab_cell.ac_tab:hover { text-decoration:none; }
.tab_content { height:640px;width:100%;padding-top:40px;position:relative; }
.tab_content:after { clear:both;content:'';display:block; }
.phone_three { float:left;width:100%;height:auto;padding-top:70%;background:url('./img/phone_three.png') no-repeat;background-size:auto 100%;background-position:center center; }
.phone_three7 { float:left;width:100%;height:auto;padding-top:70%;background:url('./img/phone_three7.png') no-repeat;background-size:auto 100%;background-position:center center; }
.phone_three2 { float:left;width:100%;height:auto;padding-top:70%;background:url('./img/phone_three2.png') no-repeat;background-size:auto 100%;background-position:center center; }
.phone_three3 { float:left;width:100%;height:auto;padding-top:70%;background:url('./img/phone_three3.png') no-repeat;background-size:auto 100%;background-position:center center; }
.phone_three4 { float:left;width:100%;height:auto;padding-top:70%;background:url('./img/phone_three4.png') no-repeat;background-size:auto 100%;background-position:center center; }
.phone_three5 { float:left;width:100%;height:auto;padding-top:70%;background:url('./img/phone_three5.png') no-repeat;background-size:auto 100%;background-position:center center; }
.phone_three6 { float:left;width:100%;height:auto;padding-top:70%;background:url('./img/phone_three6.png') no-repeat;background-size:auto 100%;background-position:center center; }
.phone_three_rest { float:left;width:calc( 100% - 0px );height:auto;text-align:center !important;padding-top:0px;font-size:19px;font-weight:lighter;color:black;line-height:29px;padding-right:0px !important;padding-bottom:70px; }
.phone_three_rest b { font-size:43px;font-weight:bold;color:black;text-align:center !important;line-height:57px !important;padding:0px !important; }
.phone_three_rest b span { color:red; }
.phone_three_rest b.smaller { font-size:22px;font-weight:bold;color:black;margin-top:32px;display:block;text-align:center !important;line-height:30px !important; }


.form { width:100%;margin:0px auto;height:auto;margin-top:86px;text-align:left;line-height:30px;font-size:16px;font-weight:bold;color:black; }
.form input[type="text"] { width:100%;height:55px;border:1px solid #d4d4d4;background:White;padding-left:20px;font-size:15px;font-weight:normal;color:black;margin-bottom:15px; }
.form textarea { width:100%;padding:20px;height:228px;border:1px solid #d4d4d4;resize:none;outline:none;font-size:15px;font-weight:normal;color:black;line-height:22px;margin-bottom:15px; }
.form pre { width:100%;padding:20px;height:228px;border:1px solid #d4d4d4;resize:none;outline:none;font-size:15px;font-weight:normal;color:black;line-height:22px;margin-bottom:15px; }



.form .checks {position: relative;display:block;vertical-align:middle;;margin-right:0px;height:30px;margin:0px auto;margin-top:0px;line-height:30px;text-align:center;margin-top:-4px;  }
.form .checks input[type="checkbox"] { position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip:rect(0,0,0,0); border: 0; }
.form .checks input[type="checkbox"] + label { display: inline; position: relative; padding-left: 25px; cursor: pointer; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none;color:#555;font-weight:normal;font-size:0.929rem;text-align:left;float:left; }
.form .checks input[type="checkbox"] + label:before { content: ''; position: absolute; left: 0; top: 6px; width: 14px; height: 14px; text-align: center; background: #fff; border: 1px solid #c8cace; border-radius: 0%; box-shadow: none; }
.form .checks input[type="checkbox"] + label:active:before, .checks input[type="checkbox"]:checked + label:active:before { box-shadow: none; }
.form .checks input[type="checkbox"]:checked + label:before { background: #31126d url('./img/ch.svg') no-repeat;background-size:100% 100%; border-color: #31126d; }
.form .checks input[type="checkbox"]:checked + label:after { content: ''; position: absolute; top: 8px; left: 3px; width: 10px; height: 10px; background:#31126d ; border-radius: 0%; box-shadow: none;display:none; }
.form label { font-size:14px;font-weight:normal;color:gray;cursor:pointer; }

.form button { cursor:pointer;width:334px;height:56px;background:red;color:white;border:none;text-align:center;display:block;margin:0px auto;margin-top:57px;font-size:19px;font-weight:normal; }
.form button:hover { background:#a40000 }
.form span.big_span { text-align:center;width:100%;height:auto;display:block;font-size:22px;font-weight:normal;color:black;margin-top:55px; }


footer { border-top:13px solid red;height:auto;width:100%;background:#4a4a4a; }
.footer_inner { margin:0px auto;width:100%;height:auto;padding-left:20px;padding-right:20px; }
.footer_inner:after { clear:both; }
.footer_logo { height:70px;width:100%;background:url('./img/footer_logo.png') no-repeat;float:none;background-position:center center; }
.footer_text { padding-top:50px;height:auto;width:100%;float:none;padding-left:0px;display:flex;align-items:center;font-size:14px;font-weight:normal;line-height:21px;color:white;text-align:center;justify-content:center; }
.footer_etc { padding-top:50px;padding-bottom:50px;;width:calc( 100% - 0px - 0px );height:auto;float:none;text-align:center;display:flex;justify-content:center;align-items:center;font-size:14px;font-weight:normal;line-height:21px;color:white; } 
.footer_etc .youtube { width:24px;border-radius:50%;height:24px;display:inline-block;vertical-align:top;margin:3px;background:url('./img/youtube.png') no-repeat;background-size:cover;background-position:center center;margin-bottom:10px; }
.footer_etc .blog { width:24px;border-radius:50%;height:24px;display:inline-block;vertical-align:top;margin:3px;background:url('./img/blog.png') no-repeat;background-size:cover;background-position:center center;margin-bottom:10px; }
.footer_etc .facebook { width:24px;border-radius:50%;height:24px;display:inline-block;vertical-align:top;margin:3px;background:url('./img/facebook.png') no-repeat;background-size:cover;background-position:center center;margin-bottom:10px; }

table.download_table { border-top:2px solid red;width:100%;height:auto;border-collapse:collapse;display:block; }
table.download_table tbody { display:block;width:100%;height:auto;margin:0px;padding:0px; }
table.download_table tbody tr { display:block;width:100%;height:auto;float:left; }
table.download_table tbody tr:after { clear:both; }
table.download_table tbody:after { clear:both; }

table.download_table td:nth-of-type(1) { width:80%;padding:20px;border-bottom:1px solid #d4d4d4;text-align:left;font-size:22px;line-height:41px;font-weight:bold;color:black;display:block;float:left;height:81px; }
table.download_table td:nth-of-type(2) { width:20%;padding:20px;border-bottom:1px solid #d4d4d4;text-align:left;font-size:22px;font-weight:bold;color:black;border-right:0px solid #d4d4d4;float:left;padding-left:0px;padding-right:10px;height:81px; }
table.download_table td:nth-of-type(3) { width:80%;padding:20px;border-bottom:1px solid #d4d4d4;text-align:left;font-size:22px;line-height:41px;font-weight:bold;color:black;padding-left:20px !important;float:left;height:81px; }
table.download_table td:nth-of-type(4) { width:20%;padding:20px;border-bottom:1px solid #d4d4d4;text-align:left;font-size:22px;font-weight:bold;color:black;float:left;padding-left:0px;padding-right:10px;height:81px; }
table.download_table td:nth-of-type(2) a { width:100%;height:40px;font-size:17px;font-weight:bold;color:black;background:white;display:block;line-height:40px;text-align:center;border:1px solid gray; }
table.download_table td:nth-of-type(2) a:hover { background:red;color:white;border:1px solid red; }
table.download_table td:nth-of-type(4) a { width:100%;height:40px;font-size:17px;font-weight:bold;color:black;background:white;display:block;line-height:40px;text-align:center;border:1px solid gray; }
table.download_table td:nth-of-type(4) a:hover { background:red;color:White;border:1px solid red; }
table.download_table tbody tr:last-of-type td:nth-of-type(3) { display:none }
table.download_table tbody tr:last-of-type td:nth-of-type(4) { display:none } 


.mobile_menu { position:fixed;top:60px;width:100%;left:0px;height:auto;background:white;z-index:9999;padding-bottom:0px;border-bottom:0px solid #d4d4d4; }
.mobile_menu a:nth-of-type(2) i { display:inline-block;width:26px;height:26px;background:url('./img/download_icon.png') no-repeat;background-size:100% 100%;vertical-align:middle;margin-right:10px; }

.mobile_menu a:nth-of-type(1) i { display:inline-block;width:26px;height:26px;background:url('./img/pc_icon.png') no-repeat;background-size:100% 100%;vertical-align:middle;margin-right:10px; }

.mobile_menu a:nth-of-type(1) { float:left;height:60px;width:50%;border-right:1px solid #d4d4d4;border-bottom:1px solid #d4d4d4;text-align:center;color:black;font-size:15px;font-weight:bold;display:block;text-align:center;line-height:60px; }
.mobile_menu a:nth-of-type(2) { float:left;height:60px;width:50%;border-right:0px solid #d4d4d4;border-bottom:1px solid #d4d4d4;text-align:center;color:black;font-size:15px;font-weight:bold;display:block;text-align:center;line-height:60px; }
.mobile_menu .menu_move { cursor:pointer;border-bottom:1px solid #d4d4d4;background:white;text-align:left;padding-left:40px;font-size:15px;font-weight:bold;color:black;height:60px;line-height:59px; }
.mobile_menu .menu_move:hover { color:white;background:red; }
.dark_wall { position:fixed;top:0px;left:0px;z-index:999;background:rgba(0,0,0,0.5);width:100%;height:100%; }

.not_mobile { display:none !important; }
.on_mobile { display:block !important; }


}



